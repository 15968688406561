<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="50">
    <div v-if="$route.name !== 'base' && $route.name !== 'login'" id="app-bar-drawer">
      <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
        <v-icon v-if="value">
          mdi-view-quilt
        </v-icon>
        <v-icon v-else>
          mdi-dots-vertical
        </v-icon>
      </v-btn>
      <v-btn v-if="fullscreenIsSupported" id="app-bar-fullscreen" class="mr-3" elevation="1" fab small @click="setFullscreen()">
        <v-icon v-if="isFullscreen">
          mdi-fullscreen-exit
        </v-icon>
        <v-icon v-else>
          mdi-fullscreen
        </v-icon>
      </v-btn>
    </div>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light" v-text="$route.meta.title" />

    <v-spacer />

    <div v-if="pageSubtitle">
      <v-toolbar-title class="hidden-sm-and-down font-weight-bold" v-text="pageSubtitle" />
    </div>

    <v-spacer />

    <div class="text-right">
      <div v-html="pageInfoLine1" />
      <div v-html="pageInfoLine2" />
    </div>

    <!--    <v-text-field-->
    <!--      :label="$t('search')"-->
    <!--      color="secondary"-->
    <!--      hide-details-->
    <!--      style="max-width: 165px;"-->
    <!--    >-->
    <!--      <template-->
    <!--        v-if="$vuetify.breakpoint.mdAndUp"-->
    <!--        v-slot:append-outer-->
    <!--      >-->
    <!--        <v-btn-->
    <!--          class="mt-n2"-->
    <!--          elevation="1"-->
    <!--          fab-->
    <!--          small-->
    <!--        >-->
    <!--          <v-icon>mdi-magnify</v-icon>-->
    <!--        </v-btn>-->
    <!--      </template>-->
    <!--    </v-text-field>-->

    <!--    <div class="mx-3" />-->

    <!--    <v-btn-->
    <!--      class="ml-2"-->
    <!--      min-width="0"-->
    <!--      text-->
    <!--      to="/"-->
    <!--    >-->
    <!--      <v-icon>mdi-view-dashboard</v-icon>-->
    <!--    </v-btn>-->

    <!--MENU-->
    <!--    <v-menu-->
    <!--      bottom-->
    <!--      left-->
    <!--      offset-y-->
    <!--      origin="top right"-->
    <!--      transition="scale-transition"-->
    <!--    >-->
    <!--      <template v-slot:activator="{ attrs, on }">-->
    <!--        <v-btn-->
    <!--          class="ml-2"-->
    <!--          min-width="0"-->
    <!--          text-->
    <!--          v-bind="attrs"-->
    <!--          v-on="on"-->
    <!--        >-->
    <!--          <v-badge-->
    <!--            color="red"-->
    <!--            overlap-->
    <!--            bordered-->
    <!--          >-->
    <!--            <template v-slot:badge>-->
    <!--              <span>5</span>-->
    <!--            </template>-->

    <!--            <v-icon>mdi-bell</v-icon>-->
    <!--          </v-badge>-->
    <!--        </v-btn>-->
    <!--      </template>-->

    <!--      <v-list-->
    <!--        :tile="false"-->
    <!--        nav-->
    <!--      >-->
    <!--        <div>-->
    <!--          <app-bar-item-->
    <!--            v-for="(n, i) in notifications"-->
    <!--            :key="`item-${i}`"-->
    <!--          >-->
    <!--            <v-list-item-title v-text="n" />-->
    <!--          </app-bar-item>-->
    <!--        </div>-->
    <!--      </v-list>-->
    <!--    </v-menu>-->

    <!--    <v-btn-->
    <!--      class="ml-2"-->
    <!--      min-width="0"-->
    <!--      text-->
    <!--      to="/pages/user"-->
    <!--    >-->
    <!--      <v-icon>mdi-account</v-icon>-->
    <!--    </v-btn>-->
  </v-app-bar>
</template>

<script>
  // Components
  // import { VHover, VListItem } from 'vuetify/lib';

  // Utilities
  import { mapState, mapMutations } from 'vuex';
  import { Logger } from '@/services/common/Logger';
  import { getPageName } from '@/services/common/Http';

  export default {
    name: 'CoreAppBar',

    // components: {
    //   AppBarItem: {
    //     render (h) {
    //       return h(VHover, {
    //         scopedSlots: {
    //           default: ({ hover }) => {
    //             return h(VListItem, {
    //               attrs: this.$attrs,
    //               class: {
    //                 'black--text': !hover,
    //                 'white--text secondary elevation-12': hover
    //               },
    //               props: {
    //                 activeClass: '',
    //                 dark: hover,
    //                 link: true,
    //                 ...this.$attrs
    //               }
    //             }, this.$slots.default);
    //           }
    //         }
    //       });
    //     }
    //   }
    // },

    props: {
      value: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      // notifications: [
      //   'Another Notification',
      //   'Another one'
      // ]
      autoCloseDrawerListener: undefined,
      isFullscreen: false,
      fullscreenIsSupported: false,
      fullscreenListener: undefined,
      pageInfoLine1: '',
      pageInfoLine2: '',
      unsubscribe: undefined
    }),
    computed: {
      ...mapState(['drawer']),
      pageSubtitle: function () {
        return this.$store.state.location?.name;
      }
    },
    mounted () {
      this.initAutoCloseDrawer();
      this.initFullscreen();
      this.updatePageInfo();
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'SET_PAGE_INFO_LINE1':
          case 'SET_PAGE_INFO_LINE2':
            this.updatePageInfo();
            break;
        }
      });
    },
    destroyed () {
      this.resetAutoCloseDrawer();
      this.resetFullscreen();
      this.updatePageInfo();
      if (typeof this.unsubscribe === 'function') {
        this.unsubscribe();
      }
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER'
      }),
      initAutoCloseDrawer () {
        this.autoCloseDrawerListener = (event) => {
          if (this.drawer) {
            const mainElement = document.getElementById('app-main');
            const appBarFullscrennElement = document.getElementById('app-bar-fullscreen');
            if (event.target === mainElement || mainElement?.contains(event.target) || event.target === appBarFullscrennElement || appBarFullscrennElement?.contains(event.target)) {
              this.setDrawer(false);
            }
          }
        };
        document.body.addEventListener('click', this.autoCloseDrawerListener);
      },
      resetAutoCloseDrawer () {
        document.body.removeEventListener('click', this.autoCloseDrawerListener);
        this.autoCloseDrawerListener = undefined;
      },
      initFullscreen () {
        if (document.fullscreenEnabled && !this.$isMobile()) {
          this.fullscreenIsSupported = document.fullscreenEnabled;
          this.resetFullscreen();
          this.fullscreenListener = () => {
            if (document.fullscreenElement !== undefined) {
              this.isFullscreen = document.fullscreenElement !== null;
              const app = document.getElementById('app');
              if (app !== null) {
                app.style.overflowY = this.isFullscreen ? 'scroll' : 'hidden';
              }
            }
          };
          document.addEventListener('fullscreenchange', this.fullscreenListener);
        }
      },
      setFullscreen () {
        const app = document.getElementById('app');
        if (app !== null) {
          const promise = this.isFullscreen ? document.exitFullscreen() : app.requestFullscreen();
          if (typeof promise === 'object') {
            promise.then(() => {
              this.isFullscreen = !this.isFullscreen;
            }).catch(Logger.exception);
          } else {
            this.isFullscreen = !this.isFullscreen;
          }
        }
      },
      resetFullscreen () {
        if (this.fullscreenListener !== undefined) {
          document.removeEventListener('fullscreenchange', this.fullscreenListener);
        }
      },
      updatePageInfo1 () {
        const pageName = getPageName();
        if (this.$store.state.page[pageName] === undefined || this.$store.state.page[pageName]?.info?.line1 === undefined || this.$store.state.page[pageName]?.info?.line1 === null) {
          this.pageInfoLine1 = '';
        } else {
          this.pageInfoLine1 = this.$store.state.page[pageName].info?.line1;
        }
      },
      updatePageInfo2 () {
        const pageName = getPageName();
        if (this.$store.state.page[pageName] === undefined || this.$store.state.page[pageName]?.info?.line2 === undefined || this.$store.state.page[pageName]?.info?.line2 === null) {
          this.pageInfoLine2 = '';
        } else {
          this.pageInfoLine2 = this.$store.state.page[pageName].info?.line2;
        }
      },
      updatePageInfo () {
        this.updatePageInfo1();
        this.updatePageInfo2();
      }
    }
  };
</script>
